.pricing {
  padding-bottom: 6.7rem;
}
.pricing::after {
  content: "";
  position: absolute;
  background: #f2f2f2 url("../../assets/images/cover/white-bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  max-height: 65rem;
}
.pricing-hero-content-wrap::before,
.pricing-hero-content-wrap::after {
  content: "";
  position: absolute;
  z-index: -1;
  background: rgba(255, 63, 147, 0.06);
  filter: blur(125px);
}

.pricing-hero-content-wrap::before {
  width: 41.9rem;
  height: 64rem;
  border-radius: 101px;
  top: -34%;
  left: 23%;
  transform: translateX(-50%);
  transform: rotate(-31.067deg);
}
.pricing-hero-content-wrap::after {
  border-radius: 504px;
  width: 50.4rem;
  height: 50.4rem;
  bottom: -28%;
  right: 4%;
}
.pricing-hero-content-wrap {
  padding: 10rem 0 12rem;
  position: relative;
}
.pricing-hero::before,
.pricing-hero::after {
  content: "";
  position: absolute;
  z-index: -1;
  filter: blur(125px);
  width: 50.4rem;
  height: 50.4rem;
}
.pricing-hero::before {
  top: -5%;
  left: 3%;
  border-radius: 504px;
  background: rgba(6, 200, 130, 0.06);
}
.pricing-hero::after {
  top: -5%;
  right: 3%;
  border-radius: 504px;
  background: rgba(255, 63, 147, 0.06);
}

.pricing-hero-content-wrap h1 {
  margin-bottom: 25px;
}

.pricing-hero-content-wrap ul li:not(:last-child) {
  margin-right: 4.8rem;
  margin-bottom: 0;
}

.pricing-cards-spacing {
  margin-top: 7rem;
}

.pricing-cards {
  border-radius: 20px;
  border: 1px solid transparent;
  background: var(--white);
  /* width: 43.2rem; */
  width: 43.3rem;
  height: 73.8rem;
  box-shadow: 0px 0px 60px 0px transparent;
  transition: 0.3s all;
  margin-bottom: 7rem;
  margin-right: 30px;
}
.pricing-cards:nth-child(3) {
  margin-right: 0px;
}
/* .pricing-cards:not(:last-child) {
  margin-right: 3.6rem;
} */
.pricing-cards:hover,
.pricing-cards:focus,
.pricing-cards:active {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
}
.pricing-card-gradient::after {
  content: "";
  position: absolute;
  width: 34rem;
  height: 17rem;
  top: 0;
  left: 50%;
  border-radius: 340px;
  transform: translateX(-50%);
  filter: blur(125px);
  background: transparent;
  opacity: 0;
  transition: 0.3s all;
}

.pricing-cards.yellow-card:hover .pricing-card-gradient::after,
.pricing-cards.yellow-card:focus .pricing-card-gradient::after,
.pricing-cards.yellow-card:active .pricing-card-gradient::after {
  opacity: 1;
  background: rgba(227, 177, 6, 0.15);
}

.pricing-cards.purple-card:hover .pricing-card-gradient::after,
.pricing-cards.purple-card:focus .pricing-card-gradient::after,
.pricing-cards.purple-card:active .pricing-card-gradient::after {
  opacity: 1;
  background: rgba(52, 18, 167, 0.15);
}

.pricing-cards.green-card:hover .pricing-card-gradient::after,
.pricing-cards.green-card:focus .pricing-card-gradient::after,
.pricing-cards.green-card:active .pricing-card-gradient::after {
  opacity: 1;
  background: rgba(6, 200, 130, 0.15);
}
.pricing-cards.pink-card:hover .pricing-card-gradient::after,
.pricing-cards.pink-card:focus .pricing-card-gradient::after,
.pricing-cards.pink-card:active .pricing-card-gradient::after {
  opacity: 1;
  background: rgba(239, 0, 100, 0.15);
}

.pricing-cards.blue-card:hover .pricing-card-gradient::after,
.pricing-cards.blue-card:focus .pricing-card-gradient::after,
.pricing-cards.blue-card:active .pricing-card-gradient::after {
  opacity: 1;
  background: rgba(0, 41, 164, 0.15);
}

.pricing-cards.yellow-card {
  border-color: var(--corn);
}
.pricing-cards.purple-card {
  border-color: var(--blue-gem);
}
.pricing-cards.green-card {
  border-color: var(--green);
}
.pricing-cards.pink-card {
  border-color: var(--rose);
}
.pricing-cards.blue-card {
  border-color: var(--international-klein-blue);
}

.pricing-cards.blue-card h2 {
  color: var(--international-klein-blue);
}
.pricing-cards.yellow-card h2 {
  color: var(--corn);
}
.pricing-cards.purple-card h2 {
  color: var(--blue-gem);
}
.pricing-cards.green-card h2 {
  color: var(--green);
}
.pricing-cards.pink-card h2 {
  color: var(--rose);
}
.pricing-cards.blue-card h2 {
  color: var(--international-klein-blue);
}

.pricing-cards.yellow-card .pricing-cards-header {
  border-bottom: 1px solid var(--corn);
}

.pricing-cards.purple-card .pricing-cards-header {
  border-bottom: 1px solid var(--blue-gem);
}

.pricing-cards.green-card .pricing-cards-header {
  border-bottom: 1px solid var(--green);
}
.pricing-cards.pink-card .pricing-cards-header {
  border-bottom: 1px solid var(--rose);
}
.pricing-cards.blue-card .pricing-cards-header {
  border-bottom: 1px solid var(--international-klein-blue);
}

.pricing-cards-header {
  padding: 5rem 30px 30px;
}
.pricing-cards-footer {
  padding: 6rem 3.9rem 6.5rem 24px;
  height: 100%;
  width: 100%;
}
.pricing-cards-footer .button {
  width: 100%;
}
.pricing-cards .list-item-wrap li:not(:last-child) {
  margin-bottom: 17px;
}

.pricing-cards-container .list-item-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}

.pricing-cards-container .list-item-wrap::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  border-radius: 5px;
}

.pricing-cards-container .list-item-wrap::-webkit-scrollbar-track {
  background: #f7f7fb;
}

.pricing-cards-container .list-item-wrap::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #cacaca;
}

/* Responsive media query */
@media screen and (max-width: 1599px) {
  .pricing-cards-container .list-item-wrap {
    max-height: 230px;
  }
  .pricing-cards .list-item-wrap li:not(:last-child) {
    margin-bottom: 12px;
  }
  .pricing-cards {
    width: 350px;
    /* height: 500px; */
  }
}
@media screen and (max-width: 1439px) {
  .pricing-cards {
    width: 350px;
    height: 580px;
  }
}

@media screen and (max-width: 1199px) {
  .pricing-cards {
    width: 380px;
  }
  .pricing-cards:nth-child(3) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .pricing-cards:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .pricing-cards {
    width: 320px;
    height: 550px;
    margin-right: 20px;
  }
  .pricing-cards-container .pricing-plan-cards-wrap:last-child {
    margin-top: 0px;
  }
  .pricing-plan-cards-wrap {
    margin-top: -20px;
  }
  .pricing-hero-content-wrap .list-item-wrap li img {
    margin-top: 0px;
  }
  .pricing-hero-content-wrap h1 {
    margin-bottom: 15px;
  }
  .pricing-hero-content-wrap .h5 {
    font-size: 15px;
  }
  .pricing-hero-content-wrap ul {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .pricing-cards-header {
    padding: 20px;
  }
  .pricing-cards {
    width: 100%;
    height: 480px;
    margin-right: 20px;
    border-radius: 16px;
  }
  .pricing-plan-cards-wrap {
    width: 100%;
    margin-top: 0;
  }
  .pricing-cards:not(:last-child) {
    margin-right: 0;
  }
  .pricing-cards {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .pricing::after {
    max-height: 200px;
  }
}
@media screen and (max-width: 575px) {
  .pricing-hero-content-wrap ul li:not(:last-child) {
    margin-bottom: 5px;
  }
  .pricing-cards-container .list-item-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

@media screen and (max-width: 480px) {
  .pricing-hero-content-wrap ul li:not(:last-child) {
    margin-bottom: 5px;
  }
  .pricing-hero-content-wrap .button {
    width: 100%;
  }
  .pricing-hero-content-wrap .button:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .pricing-cards-header {
    padding: 15px;
  }
}
@media screen and (max-width: 414px) {
  .pricing-cards-footer .h6 {
    font-size: 14px;
  }
  .pricing::after {
    max-height: 160px;
  }
}
