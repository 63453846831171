/*  Global Start  */

:root {
  /*  Fonts  */
  --primary-font: "Eina 01";

  /*  Theme Colors  */
  --white: #ffffff;
  --black: #000000;
  --green: #06c882;
  --light-green: #05c882;
  --orange-white: #fef8ed;
  --Chablis: #fff1f1;
  --prim: #f5edf4;
  --zumthor: #e8eeff;
  --aqua-spring: #eaf8f6;
  --yellow: #f5c107;
  --corn: #e3b106;
  --blue-gem: #3412a7;
  --mine-shaft: #212121;
  --mine-shaft-1: #202020;
  --deep-cove: #150646;
  --dove-gray: #666666;
  --cruise: #c1ecde;
  --tara: #daf3e8;
  --shamrock: #22d497;
  --royal-blue: #5e36e9;
  --emperor: #535353;
  --medium-purple: #6c63e2;
  --racing-green: #0c1311;
  --racing-green-1: #0d1311;
  --rose: #ef0064;
  --international-klein-blue: #0029a4;
  --header-height: 11.6rem;
}

html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--primary-font);
  color: var(--dark-gray);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  background-color: var(--white);
}

@font-face {
  font-family: "Eina 01";
  src: url("./assets/webfonts/Eina01-Bold.woff") format("woff2"),
    url("./assets/webfonts/Eina01-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Eina 01";
  src: url("./assets/webfonts/Eina01-Regular.woff2") format("woff2"),
    url("./assets/webfonts/Eina01-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Eina 01";
  src: url("./assets/webfonts/Eina01-Light.woff2") format("woff2"),
    url("./assets/webfonts/Eina01-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Eina 01";
  src: url("./assets/webfonts/Eina01-SemiBold.woff2") format("woff2"),
    url("./assets/webfonts/Eina01-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Eina 01 RegularItalic";
  src: url("./assets/webfonts/Eina01-RegularItalic.woff2") format("woff2"),
    url("./assets/webfonts/Eina01-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

.h1,
h1 {
  font-size: 5.4rem;
}

.h1.small,
h1.small {
  font-size: 5rem;
}

.h2,
h2 {
  font-size: 4rem;
}

.h2.small,
h2.small {
  font-size: 3.4rem;
}

.h3,
h3 {
  font-size: 26px;
}

.h4,
h4 {
  font-size: 22px;
}

.h5,
h5 {
  font-size: 20px;
}

.h6,
h6 {
  font-size: 18px;
}

.p,
p {
  font-size: 16px;
}

.p.small,
p.small {
  font-size: 14px;
}

.small-content {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.text-white {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

.text-mine-shaft-1 {
  color: var(--mine-shaft-1);
}

.text-mine-shaft {
  color: var(--mine-shaft);
}

.text-green {
  color: var(--green);
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  outline: none;
  text-decoration: none;
  transition: 0.3s all;
}

.container {
  padding: 0 15px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  background: var(--green);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: var(--green);
}

::selection {
  color: var(--white);
  background: var(--green);
}
/* Global End */

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1800px) {
  .container {
    max-width: 1400px;
  }
}

@media screen and (max-width: 1799px) {
  html {
    font-size: 9px;
  }

  .h3,
  h3 {
    font-size: 24px;
  }

  .h4,
  h4 {
    font-size: 20px;
  }

  .h5,
  h5 {
    font-size: 18px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1599px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 1439px) {
  html {
    font-size: 7px;
  }

  .h3,
  h3 {
    font-size: 22px;
  }

  .h4,
  h4 {
    font-size: 19px;
  }
  .h5,
  h5 {
    font-size: 17px;
  }
}

@media screen and (max-width: 1365px) {
  html {
    font-size: 6px;
  }
}

@media screen and (max-width: 1199px) {
  html {
    font-size: 5px;
  }
  .h1,
  h1 {
    font-size: 32px;
  }
  .h2,
  h2 {
    font-size: 27px;
  }
  .h3,
  h3 {
    font-size: 20px;
  }
  .h4,
  h4 {
    font-size: 18px;
  }
  /* .h5,
  h5 {
    font-size: 16px;
  } */
}
@media screen and (max-width: 991px) {
  .h1,
  h1 {
    font-size: 29px;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 4px;
  }
  .h3,
  h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  .h4,
  h4 {
    font-size: 17px;
  }
}

@media screen and (max-width: 480px) {
  .h1,
  h1 {
    font-size: 27px;
  }
  .h2,
  h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 350px) {
  .h1,
  h1 {
    font-size: 25px;
  }
}
