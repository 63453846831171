.hero {
  padding: 14.7rem 0 22rem;
}
.hero-wrap {
  max-width: 50%;
}

.list-item-wrap li {
  display: flex;
  align-items: flex-start;
}
.list-item-wrap li:not(:last-child) {
  margin-bottom: 10px;
}
.list-item-wrap li img {
  margin-right: 15px;
  margin-top: 4px;
}
.list-item-wrap li .h6 {
  line-height: normal;
}
.hero-img {
  position: absolute;
  right: -14%;
  top: 50%;
  transform: translateY(-50%);
  width: 95rem;
  height: 66.7rem;
}
.hero-bg-img {
  position: absolute;
  left: 0;
  top: 25px;
  width: 34rem;
  height: 78.2rem;
}

.quick-demo {
  padding-bottom: 13.5rem;
}
.quick-demo-wrap {
  max-width: 80%;
  /* max-width: 61.61%; */
  margin: 0 auto;
}
.quick-demo-wrap h2 {
  margin-bottom: 5rem;
}
.quick-links-wrap {
  /* flex-shrink: 0; */
  flex: 1 1;
  text-align: center;
}
.link-group-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  padding: 12px;
  flex-shrink: 0;
  z-index: 1;
  border-radius: 50%;
}

.quick-links-wrap .link-group-icons {
  margin-bottom: 19px;
}

.link-group-icons-bg-gold {
  background-color: var(--orange-white);
}
.link-group-icons-bg-pink {
  background-color: var(--Chablis);
}
.link-group-icons-bg-purple {
  background-color: var(--prim);
}
.link-group-icons-bg-blue {
  background-color: var(--zumthor);
}
.link-group-icons-bg-green {
  background-color: var(--aqua-spring);
}
.advanced-practice-img {
  width: calc(100% - 28.3%);
}
.advanced-practice-img img {
  width: 112.5rem;
  height: 74.8rem;
}

.advanced-practice-img > div {
  position: relative;
  left: -13rem;
}
.advanced-practice-title-text {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
  text-align: center;
  width: 30%;
  margin: 0 auto;
  display: inline-block;
}
.advanced-practice-wrap {
  width: 28.3%;
}

.advanced-practice h2 {
  margin-bottom: 8.5rem;
}

.advanced-practice-groups-wrap:not(:last-child) {
  margin-bottom: 28px;
}
.advanced-practice-groups-wrap .link-group-icons {
  margin-right: 5.7rem;
}

.key-advantages {
  position: relative;
  padding: 10rem 0 20rem;
}
.bg {
  position: relative;
}
.bg::after {
  content: "";
  position: absolute;
  background: transparent
    url("../../assets/images/cover/key-advantages-new-bg.png");
  background-size: cover;
  width: 100%;
  height: 228.1rem;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
/* .key-advantages::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/images/cover/key-advantages-bg.png") no-repeat
    center;
  background-size: cover;
  width: 100%;
  height: 100%;
} */
.key-advantages h2 {
  margin-bottom: 6.2rem;
}

.key-advantages-wrap {
  width: 40.66%;
  flex-shrink: 0;
}

.key-advantages-img-wrap {
  width: calc(100% - 40.66%);
}
.key-advantages-img-wrap img {
  width: 90.2rem;
  height: 78.3rem;
}
.key-advantages-items {
  background-color: var(--white);
  box-shadow: 0px 15px 60px 0px #00000033;
  padding: 22px 4.2rem 22px 29px;
  border-radius: 12px;
  /* height: 8.5rem; */
  max-width: 51.6rem;
}

.key-advantages-items img {
  width: 53px;
  height: auto;
}

.key-advantages-items:not(:last-child) {
  margin-bottom: 28px;
}
.key-advantages-items:nth-child(even) {
  transform: translateX(-41px);
}

.interactions {
  padding: 14.8rem 0;
  background: #f2f2f2 url("../../assets/images/cover/interactions-bg.png")
    no-repeat;
  background-size: cover;
  /* margin-top: -6rem; */
}

.interactions-wrap {
  max-width: 115.2rem;
  margin: 0 auto;
  margin-top: -33rem;
}
.interactions-wrap img {
  margin: 0 auto;
  width: 33rem;
  height: 33rem;
}
.interactions-wrap span {
  margin: 7.5rem 0 5.7rem;
  display: inline-block;
}
.patient-interactions {
  position: absolute;
  top: 0;
  left: 0;
}
.customer-review {
  padding-bottom: 9rem;
}
.customer-review-img {
  width: 65.6rem;
}
.customer-review-img img {
  width: 65.6rem;
  height: 97.4rem;
}
.customer-review-slider-wrap {
  width: calc(100% - 65.6rem);
}

.customer-review h2 {
  padding-left: 11.7rem;
}
.customer-review .swiper-wrapper {
  padding: 5.7rem 0 7.5rem 11.7rem;
  /* transform: unset !important; */
}

.customer-review-card {
  position: relative;
  background-color: var(--white);
  box-shadow: 0px 4px 60px 0px #00000026;
  padding: 5rem 4rem;
  border-bottom-right-radius: 10rem;
  z-index: 1;
}

.customer-review-card::before {
  content: "";
  position: absolute;
  background: url("../../assets/images/icons/quotes.png") no-repeat;
  background-size: cover;
  top: 100px;
  left: 30px;
  width: 8.64rem;
  height: 6.1rem;
  z-index: -1;
}
.customer-review-card .star-img {
  margin-bottom: 3.6rem;
}
.customer-review-card p {
  font-style: italic;
  line-height: 2;
  font-weight: 400;
  margin-bottom: 5.4rem;
}
.customer-review-card h6 {
  line-height: 1.38;
}
.customer-review-card .customer-review-subtitle {
  color: var(--dove-gray);
  line-height: 19.32px;
}
.customer-review-profile img {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  flex-shrink: 0;
  margin-right: 18px;
}

.customer-review-slider-wrap .swiper-pagination {
  padding-left: 11.7rem;
}

.customer-review-slider-wrap .swiper-pagination-bullet {
  min-width: 20px;
  border: 0;
  border-radius: 20px;
  background-color: rgb(0, 36, 75, 15%);
  transition: 0.5s all;
  min-height: 8px;
}

.customer-review-slider-wrap
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--green);
}

.customer-review-slider-wrap
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--green);
  min-width: 60px;
}
.customer-review-slider-wrap .swiper-wrapper {
  margin-bottom: 7.5rem;
}
.customer-review-slider-wrap .swiper-pagination {
  text-align: left;
}

.customer-review-card .dv-star-rating {
  margin-bottom: 3.6rem;
}

.our-mission {
  background: url("../../assets/images/cover/our-mission-bg.png") no-repeat;
  background-size: cover;
}

.our-mission h2 {
  margin-bottom: 4.4rem;
}
.our-mission-wrap {
  max-width: 61.32%;
  margin: 0 auto;
  margin-top: 14rem;
}
.mission-steps {
  position: absolute;
}
.steps-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 93%;
}

.mission-steps-circle {
  background: var(--tara);
  border: 1px solid transparent;
  width: 10rem;
  height: 10rem;
  padding: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.mission-steps-inner-content {
  position: absolute;
}
.mission-steps-inner-content h5 {
  line-height: 1.38;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.mission-steps-1 {
  top: 65%;
  left: 12%;
  transform: translateY(-50%);
}
.mission-steps-1 h5,
.mission-steps-2 h5 {
  text-align: end;
}
.mission-steps-1 .mission-steps-inner-content {
  top: 50%;
  right: 300%;
  min-width: 18rem;
  transform: translateY(-50%);
}

.mission-steps-2 {
  bottom: 56%;
  left: 24%;
}

.mission-steps-2 .mission-steps-inner-content {
  top: 50%;
  right: 300%;
  min-width: 25rem;
  transform: translateY(-50%);
}

.mission-steps-3 {
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.mission-steps-3 .steps-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -60%;
}
.mission-steps-3 h5 {
  text-align: center;
}
.mission-steps-3 .mission-steps-inner-content {
  top: -144%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 35rem;
}
.mission-steps-4 {
  right: 24%;
  top: 24%;
}
.mission-steps-4 .mission-steps-inner-content {
  top: 50%;
  left: 290%;
  transform: translateY(-50%);
  min-width: 21rem;
}
.mission-steps-4 .steps-line,
.mission-steps-5 .steps-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: 93%;
}
.mission-steps-5 {
  top: 67%;
  right: 11%;
  transform: translateY(-50%);
}
.mission-steps-5 .mission-steps-inner-content {
  top: 50%;
  left: 290%;
  transform: translateY(-50%);
  min-width: 19rem;
}
/* .customer-review-swipper-slider .swiper-slide {
  width: 62rem !important;
} */

/* Responsive Media Query */

@media screen and (max-width: 1799px) {
  .mission-steps-circle .our-mision-icon {
    max-width: 100%;
    height: auto;
  }
  .hero-img {
    right: -10%;
  }
  .advanced-practice-img > div {
    left: -8%;
  }
  .advanced-practice-groups-wrap .link-group-icons {
    margin-right: 25px;
  }
  .customer-review-card::before {
    top: 90px;
    left: 33px;
  }
}
@media screen and (max-width: 1599px) {
  .mission-steps-circle {
    width: 90px;
    height: 90px;
  }
  .mission-steps-1 .mission-steps-inner-content {
    /* right: 300%; */
    min-width: 160px;
  }

  .mission-steps-3 .steps-line {
    top: -70%;
  }
  .hero-wrap {
    max-width: 45%;
  }
  .hero-img {
    right: -5%;
  }
  .advanced-practice-img > div {
    left: 0%;
  }
  .customer-review-card {
    padding: 30px 25px;
  }
  .customer-review-card .dv-star-rating {
    margin-bottom: 15px;
  }
  .customer-review-card p {
    margin-bottom: 25px;
  }
  .customer-review-card::before {
    top: 65px;
    left: 20px;
  }
  .advanced-practice-title-text {
    left: 40%;
  }
}
@media screen and (max-width: 1439px) {
  .key-advantages-items img {
    width: 43px;
  }
  .mission-steps-4 .mission-steps-inner-content {
    min-width: 160px;
  }
  .mission-steps-circle {
    width: 80px;
    height: 80px;
  }
  .mission-steps-1 .mission-steps-inner-content {
    right: 325%;
    min-width: 160px;
  }

  .mission-steps-2 .mission-steps-inner-content {
    right: 325%;
  }
  .mission-steps-4 .mission-steps-inner-content,
  .mission-steps-5 .mission-steps-inner-content {
    left: 325%;
  }
  .mission-steps-3 .steps-line {
    top: -75%;
  }
  .key-advantages-wrap {
    width: 45%;
  }
  .key-advantages-img-wrap {
    width: calc(100% - 45%);
  }
  .key-advantages-items {
    max-width: 400px;
  }
  .customer-review-card::before {
    top: 76px;
    left: 24px;
  }
  .advanced-practice h2 {
    margin-bottom: 20px;
  }
  .customer-review-card p {
    margin-bottom: 20px;
    line-height: 1.8;
  }
  .key-advantages-items:not(:last-child) {
    margin-bottom: 18px;
  }
  .customer-review-card::before {
    top: 66px;
    left: 20px;
  }
}
@media screen and (max-width: 1365px) {
  .key-advantages-items img {
    width: 38px;
  }
  .key-advantages-items .h5 {
    font-size: 15px;
  }
  .key-advantages-items {
    padding: 15px 20px;
    /* height: 55px; */
    max-width: 340px;
  }
  .list-item-wrap li img {
    margin-right: 10px;
    margin-top: 2px;
  }
  .interactions-wrap .interactions-img {
    width: 220px;
    height: 220px;
  }

  .our-mission .h2 {
    font-size: 26px;
  }
  .mission-steps-3 .mission-steps-inner-content {
    min-width: 310px;
  }
  .mission-steps-2 .mission-steps-inner-content {
    min-width: 220px;
  }
  .hero-img {
    right: 0;
  }
  .advanced-practice-groups-wrap:not(:last-child) {
    margin-bottom: 18px;
  }
  .quick-demo-wrap {
    max-width: 70%;
  }
  .advanced-practice-img {
    width: calc(100% - 40%);
  }
  .advanced-practice-wrap {
    width: 40%;
  }
  .key-advantages-wrap {
    padding-left: 45px;
  }
  .customer-review-profile img {
    width: 45px;
    height: 45px;
    margin-right: 14px;
  }
  .customer-review-card {
    padding: 20px;
  }
  .customer-review-card p {
    line-height: 1.7;
  }
  .customer-review-card::before {
    top: 58px;
    left: 18px;
  }
}

@media screen and (max-width: 1199px) {
  .link-group-icons {
    width: 60px;
    height: 60px;
  }
  .interactions-wrap .interactions-img {
    width: 190px;
    height: 190px;
  }
  .interactions-wrap {
    margin-top: -190px;
  }
  .mission-steps-circle {
    padding: 16px;
  }
  .mission-steps-circle {
    width: 70px;
    height: 70px;
  }
  /* .mission-steps-4 .steps-line,
  .mission-steps-5 .steps-line {
    width: 150px;
  } */
  .steps-line {
    width: 130px;
  }
  .mission-steps-3 .steps-line {
    width: auto;
    height: 56px;
  }
  .mission-steps-3 .mission-steps-inner-content {
    min-width: 320px;
    top: -136%;
  }
  .mission-steps-4 .mission-steps-inner-content,
  .mission-steps-5 .mission-steps-inner-content {
    left: 285%;
  }
  .mission-steps-2 .mission-steps-inner-content,
  .mission-steps-1 .mission-steps-inner-content {
    right: 285%;
  }
  .mission-steps-5 .mission-steps-inner-content {
    min-width: 130px;
  }
  .mission-steps-4 .mission-steps-inner-content {
    min-width: 180px;
  }
  .mission-steps-2 .mission-steps-inner-content {
    min-width: 180px;
  }
  .our-mission-wrap {
    margin-top: 80px;
  }
  .interactions-wrap .h1 {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .interactions-wrap {
    margin-top: 0;
  }
  .mission-steps {
    position: relative;
  }

  .mission-steps:not(:last-child) {
    margin-bottom: 15px;
  }
  .our-mission-wrap {
    margin-top: 0px;
    max-width: 100%;
  }
  .mission-steps-inner {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .mission-steps-1 {
    top: unset;
    left: unset;
    transform: unset;
  }
  .mission-steps-2 {
    bottom: unset;
    left: unset;
  }
  .mission-steps-4 {
    right: unset;
    top: unset;
  }
  .mission-steps-5 {
    top: unset;
    right: unset;
    transform: unset;
  }
  .mission-steps-inner-content {
    position: relative;
  }
  .mission-steps-circle {
    margin-right: 12px;
  }
  .mission-steps-1 .mission-steps-inner-content,
  .mission-steps-2 .mission-steps-inner-content {
    top: unset;
    right: unset;
    min-width: unset;
    transform: unset;
  }

  .mission-steps-4 .mission-steps-inner-content,
  .mission-steps-5 .mission-steps-inner-content,
  .mission-steps-3 .mission-steps-inner-content {
    top: unset;
    left: unset;
    transform: unset;
    min-width: unset;
  }
  .mission-steps-3 h5 {
    text-align: start;
  }
  .mission-steps-1 h5,
  .mission-steps-2 h5 {
    text-align: unset;
  }
  .circle-img,
  .steps-line {
    display: none;
  }
  .our-mission {
    padding-bottom: 40px;
  }
  .interactions {
    padding: 40px 0;
  }
  .hero-wrap {
    max-width: 100%;
    order: 2;
  }
  .hero-img {
    position: relative;
    top: unset;
    transform: unset;
    order: 1;
  }
  .hero-bg-img {
    display: none;
  }
  .hero {
    padding: 40px 0 60px;
  }
  .hero-layout {
    justify-content: center;
  }
  .quick-demo-wrap {
    max-width: 100%;
  }
  .advanced-practice-wrap,
  .advanced-practice-img {
    width: 100%;
  }
  .advanced-practice-groups-wrap {
    width: 100%;
  }
  .advanced-practice-groups-wrap:not(:last-child) {
    margin-bottom: 10px;
  }
  .bg::after {
    display: none;
  }
  .quick-links-wrap .link-group-icons,
  .advanced-practice-wrap .link-group-icons {
    width: 70px;
    height: 70px;
  }
  .key-advantages h2 {
    margin-bottom: 25px;
    text-align: center;
  }
  .key-advantages-wrap,
  .key-advantages-img-wrap {
    width: 100%;
  }
  .key-advantages-img-wrap {
    text-align: center;
  }
  .key-advantages-wrap {
    padding-left: 0px;
    order: 2;
  }
  .key-advantages-img-wrap img {
    width: 90.2rem;
    height: 78.3rem;
    order: 1;
    margin-bottom: 20px;
  }
  .key-advantages-items {
    max-width: 450px;
    margin: 0 auto;
  }
  .key-advantages-items:nth-child(even) {
    transform: unset;
  }
  .customer-review-img img {
    display: none;
  }
  .customer-review-slider-wrap {
    width: 100%;
  }
  .customer-review-card::before {
    top: 66px;
    left: 19px;
  }
  .customer-review h2 {
    text-align: center;
    padding-left: 0;
  }
  .advanced-practice-img > div {
    text-align: center;
  }
  .customer-review .swiper-wrapper {
    padding: 30px 15px;
  }

  .customer-review-card::before {
    top: 60px;
    left: 19px;
  }
  .customer-review-slider-wrap .swiper-pagination {
    padding-left: 15px;
  }
  .advanced-practice-title-text {
    width: 26%;
  }
}
@media screen and (max-width: 767px) {
  .link-group-icons {
    width: 70px;
    height: 70px;
  }
  .hero-img {
    width: 400px;
    height: 300px;
  }
  .hero-wrap .h1 {
    font-size: 27px;
  }
  .quick-demo-wrap {
    max-width: 100%;
  }
  .interactions-wrap .h1 {
    font-size: 22px;
  }
  .key-advantages-img-wrap img {
    width: 410px;
    height: 330px;
  }
  .quick-demo {
    padding-bottom: 50px;
  }
  .quick-links-wrap {
    flex: 1 1 130px;
    margin-bottom: 20px;
  }
  .customer-review-card {
    box-shadow: 0px 4px 30px 0px #00000026;
  }
  /* .customer-review-swipper-slider .swiper-slide {
    width: 545px !important;
  } */
  .customer-review .swiper-wrapper {
    padding: 15px;
  }
}
@media screen and (max-width: 575px) {
  .key-advantages-items:nth-child(even) {
    transform: translateX(0px);
  }
  .key-advantages-items {
    max-width: 400px;
    box-shadow: 0px 11px 50px 0px #00000033;
  }
  .hero {
    padding: 20px 0 50px;
  }
  .hero-wrap .h1,
  .key-advantages h2,
  .advanced-practice h2,
  .customer-review h2,
  .quick-demo-wrap h2 {
    font-size: 24px;
  }
  .advanced-practice h2 {
    margin-bottom: 15px;
    /* font-size: 24px; */
  }
  .quick-demo {
    padding-bottom: 30px;
  }
  .advanced-practice-groups-wrap .link-group-icons {
    margin-right: 10px;
  }
  .customer-review-card .star-img {
    width: 36%;
  }
  .quick-links-wrap .link-group-icons {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 480px) {
  .mission-steps-inner-content h5 {
    line-height: 1.2;
  }
  .advanced-practice-img img {
    width: 370px;
    height: 260px;
  }
  .key-advantages-img-wrap img {
    width: 370px;
    height: 300px;
  }
  .quick-links-wrap {
    flex: 1 1 140px;
  }
  .quick-links-wrap .h5 {
    font-size: 16px;
  }
  .advanced-practice-title-text {
    left: 40%;
    width: 30%;
  }
  .advanced-practice-img .h1.small {
    font-size: 16px;
  }
}
@media screen and (max-width: 414px) {
  .list-item-wrap li img {
    width: 18px;
    height: 18px;
    margin-top: 0px;
  }
  .hero-img {
    width: 360px;
    height: 260px;
  }
  .quick-links-wrap .link-group-icons,
  .advanced-practice-wrap .link-group-icons {
    width: 65px;
    height: 65px;
  }
  .advanced-practice-img img {
    width: 330px;
    height: 210px;
  }
  .key-advantages-img-wrap img {
    width: 310px;
    height: 250px;
  }
  .interactions-wrap .interactions-img {
    width: 170px;
    height: 170px;
  }
  .interactions {
    padding: 30px 0;
  }
  .hero-wrap .h1,
  .key-advantages h2,
  .advanced-practice h2,
  .customer-review h2,
  .quick-demo-wrap h2 {
    font-size: 22px;
  }
  .customer-review-card p {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) {
  .hero-img {
    width: 300px;
    height: 200px;
  }
}

@media screen and (max-width: 350px) {
  .key-advantages-img-wrap img {
    width: 270px;
    height: 200px;
  }
  .advanced-practice-img img {
    width: 280px;
    height: 200px;
  }
  .advanced-practice-title-text {
    left: 37%;
    width: 34%;
  }
}
