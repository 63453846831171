footer {
  position: relative;
  background-color: #f2f2f2;
}

.footer-header {
  padding: 10rem 0 9rem;
}

.footer-logo-wrap {
  display: inline-block;
  margin-bottom: 7.2rem;
}

.footer-logo-wrap img {
  width: 100%;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 10px;
  color: var(--racing-green);
  background-color: var(--white);
  border-radius: 50%;
  transition: 0.3s all ease;
  flex-shrink: 0;
}

.social-icon:hover {
  background-color: var(--green);
  color: var(--white);
}

.social-icon:not(:last-child) {
  margin-right: 8px;
}

.footer-links a {
  display: block;
  color: rgba(0, 0, 0, 0.6);
  transition: 0.3s all ease;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.94;
}

.footer-links a:hover,
.footer-links a:active,
.footer-links a:focus {
  color: rgba(0, 0, 0, 1);
}

.footer-copyright {
  padding: 5rem 0;
  text-align: center;
}

.footer-copyright .footer-text {
  font-size: 16px;
  color: var(--emperor);
  line-height: 22.08px;
  font-weight: 600;
}

.footer-bottom {
  border-top: 1px solid rgba(0, 0, 0, 20%);
}

.footer-copyright .footer-text span {
  color: #50c596;
}

.footer-bg-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42.5rem;
}

/* Responsive Design */

@media screen and (max-width: 1799px) {
  .footer-links a {
    font-size: 17px;
  }
}

@media screen and (max-width: 1365px) {
  .footer-links a {
    font-size: 16px;
  }

  .footer-copyright .footer-text {
    font-size: 15px;
  }

  .footer-copyright {
    padding: 3.5rem 0;
  }
}

@media screen and (max-width: 1199px) {
  .footer-links a {
    font-size: 15px;
  }
  .footer-logo-wrap img {
    width: 85%;
  }
}

@media screen and (max-width: 991px) {
  .footer-links-wrap {
    margin-top: 20px;
  }
  .footer-logo-wrap img {
    width: 150px;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-wrap {
    margin-top: 0px;
  }
  .footer-links:last-child {
    width: 100%;
  }
  .footer-links {
    margin-top: 20px;
  }
  .footer-links-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 575px) {
  .footer-copyright .footer-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .footer-links:nth-child(2) {
    width: 100%;
  }
  .footer-logo-wrap img {
    width: 140px;
}
}
