.solutions-left-spacing {
  padding-left: 14rem;
}
.mental-health {
  padding: 12rem 0 13.7rem;
}
.dentist-wrap {
  position: relative;
  padding-bottom: 12rem;
}
.dentist-wrap::after {
  content: "";
  position: absolute;
  background: #f2f2f2 url("../../assets/images/cover/white-bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  max-height: 65rem;
}

.title-wrap .h1 {
  margin-bottom: 30px;
}
.solutions-hero-img-wrap img {
  width: 78.8rem;
  height: 62.8rem;
}
.mental-health-img,
.general-practice-img,
.consultant-img,
.dentist-img {
  width: 81.3rem;
  height: 54rem;
}

/* Responsive media query */
@media screen and (max-width: 1439px) {
  .title-wrap .h1 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1365px) {
  .mental-health-img,
  .general-practice-img,
  .consultant-img,
  .dentist-img {
    width: 560px;
    height: 380px;
  }
  .solutions-hero-img-wrap img {
    width: 490px;
    height: 410px;
  }
  .feature-hero-img-wrap {
    left: -70px;
  }
}

@media screen and (max-width: 1199px) {
  .solutions-hero-img-wrap img {
    width: 450px;
    height: 380px;
  }
  .feature-hero-list-wrap {
    grid-gap: 10px;
  }
  .list-item-wrap .h5 {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .feature-hero {
    padding: 30px 0;
  }
  .feature-hero-img-wrap {
    left: unset;
    text-align: center;
    margin: 0;
  }
  .solutions-left-spacing {
    padding-left: 0;
  }
  .solutions-img-wrap {
    text-align: center;
  }

  .solutions-img-wrap img {
    margin-bottom: 15px;
  }
  .mental-health {
    padding: 40px 0;
  }
  .mental-health-img,
  .general-practice-img,
  .consultant-img,
  .dentist-img {
    width: 500px;
    height: 350px;
  }
}

@media screen and (max-width: 767px) {
  .feature-hero-list-wrap {
    grid-gap: 0;
  }
  .dentist-wrap::after {
    max-height: 200px;
  }
}

@media screen and (max-width: 575px) {
  .mental-health-img,
  .general-practice-img,
  .consultant-img,
  .dentist-img {
    width: 400px;
    height: 280px;
  }
  .solutions-hero-img-wrap img {
    width: 380px;
    height: 320px;
  }
  .dentist-wrap::after {
    max-height: 160px;
  }
}
@media screen and (max-width: 480px) {
  .feature-hero-wrap .h1,
  .title-wrap .h1 {
    font-size: 27px;
  }
  .title-wrap .h1 {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 414px) {
  .solutions-hero-img-wrap img {
    width: 330px;
    height: 280px;
  }
  .mental-health-img,
  .general-practice-img,
  .consultant-img,
  .dentist-img {
    width: 320px;
    height: 220px;
  }
  .feature-hero-wrap .h1,
  .title-wrap .h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 350px) {
  .solutions-hero-img-wrap img {
    width: 280px;
    height: 250px;
  }
  .mental-health-img,
  .general-practice-img,
  .consultant-img,
  .dentist-img {
    width: 290px;
    height: 180px;
  }
  .list-item-wrap .h5 {
    font-size: 15px;
  }
  
}
