.feature-hero {
  background: #ffffff url("../../assets/images/cover/feature-hero-bg.jpg")
    no-repeat center center;
  background-size: cover;
  position: relative;
  /* padding-top: 10rem;
  padding-bottom: 13rem; */
  z-index: 1;
  min-height: 64.9rem;
  max-height: 64.9rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.feature-manage-practice-wrap {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.feature-hero-wrap {
  max-width: 60%;
  flex-shrink: 0;
}
.feature-hero-wrap h1 {
  color: var(--racing-green-1);
  line-height: 1.1;
  margin-bottom: 3.6rem;
  max-width: 60%;
}
.feature-hero-list-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.feature-hero-img-wrap {
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16 rem; */
  position: relative;
  left: -80px;
}
.feature-hero-img-wrap img {
  margin-bottom: -6rem;
  width: 75rem;
  height: 58.5rem;
}
.feature-hero-list-wrap .list-item-wrap li:not(:last-child) {
  margin-bottom: 20px;
}

.manage-your-practice {
  padding: 10.8rem 0 12.2rem;
}
.manage-your-practice h2 {
  margin-bottom: 7rem;
}
.manage-practice-groups-wrap {
  width: 100%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background: linear-gradient(
    270deg,
    #fff 1.8%,
    rgba(255, 255, 255, 0) 75.76%,
    rgba(255, 255, 255, 0) 97.75%
  );
  box-shadow: 120px 0px 150px 0px rgba(0, 0, 0, 0.08);
}
.manage-practice-groups-title-wrap {
  position: relative;
  left: -50px;
}

.link-group-icons img {
  object-fit: cover;
  height: auto;
  max-width: 100%;
  width: 70%;
}
.manage-practice-groups-wrap:not(:last-child) {
  margin-bottom: 18px;
}

.manage-your-practice-wrap {
  position: relative;
  z-index: 1;
}
.manage-practice-left-wrap,
.manage-practice-right-wrap {
  max-width: 47rem;
}
.manage-practice-left-wrap .link-group-icons {
  left: -50px;
  position: relative;
  margin-right: 16px;
}
.manage-practice-right-wrap .link-group-icons {
  right: -50px;
  position: relative;
}
.manage-practice-left-wrap::after,
.manage-practice-right-wrap::after {
  content: "";
  position: absolute;
  width: 28rem;
  height: 28rem;
  top: 50%;
  transform: translateY(-50%);
  filter: blur(50px);
  z-index: -1;
  border-radius: 28rem;
}
.manage-practice-left-wrap::after {
  right: 6rem;
  background: rgba(1, 204, 116, 0.08);
}
.manage-practice-right-wrap::after {
  left: 6rem;
  background: rgba(255, 63, 147, 0.06);
}

.manage-practice-right-wrap .manage-practice-groups-wrap {
  position: relative;
  left: unset;
  z-index: 1;
  justify-content: space-between;
  background: linear-gradient(
    -270deg,
    #fff 1.8%,
    rgba(255, 255, 255, 0) 75.76%,
    rgba(255, 255, 255, 0) 97.75%
  );
  box-shadow: -120px 0px 150px 0px rgba(0, 0, 0, 0.08);
}
.manage-practice-right-wrap .manage-practice-groups-title-wrap {
  position: relative;
  left: 0px;
  margin-right: -50px;
  padding-right: 0px;
  padding-left: 30px;
}
.manage-practice-img-wrap img {
  width: 30rem;
  height: 30rem;
}
.manage-practice-img-wrap::after {
  content: "";
  position: absolute;
  background: url("../../assets/images/cover/mange-your-practice-steps-line.png")
    no-repeat;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 54rem;
  height: 37.8rem;
  z-index: -1;
}

.payment-solutions::after {
  content: "";
  position: absolute;
  background: #f2f2f2 url("../../assets/images/cover/white-bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  max-height: 65rem;
}

.payment-solutions-bg {
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
}

.payment-solutions {
  padding-bottom: 11.2rem;
}

.payment-solutions-wrap {
  background-color: #6048e4;
  border-top-right-radius: 20rem;
  border-bottom-left-radius: 20rem;
  padding: 9.4rem 34px 10rem 9.6rem;
  position: relative;
  z-index: 1;
}
.payment-solutions-wrap h2 {
  margin-bottom: 5.7rem;
  margin-left: 4.5rem;
}
.payment-solutions-content-wrap {
  margin-left: 4.5rem;
}
.payment-solutions-content-wrap .key-advantages-items {
  background-color: #6c63e2;
  box-shadow: unset;
}
.payment-solutions .btn-wrap {
  margin-top: 6rem;
  margin-left: 8rem;
}

/* Responsvie Design */
@media screen and (max-width: 1799px) {
}
@media screen and (max-width: 1599px) {
  .feature-hero-img-wrap {
    left: -20px;
  }
  .manage-practice-groups-wrap .h4 {
    font-size: 18px;
  }
  .manage-your-practice-wrap {
    max-width: 90%;
    margin: 0 auto;
  }
  .feature-hero-list-wrap .list-item-wrap li:not(:last-child) {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 1439px) {
}

@media screen and (max-width: 1365px) {
  .feature-hero {
    min-height: 440px;
  }
  .manage-your-practice-wrap {
    max-width: 80%;
  }
  .manage-practice-groups-wrap .h4 {
    font-size: 16px;
  }
  .manage-practice-right-wrap .manage-practice-groups-title-wrap {
    padding-left: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .manage-practice-img-wrap img {
    width: 160px;
    height: 160px;
  }
  .manage-practice-img-wrap::after {
    width: 300px;
    height: 210px;
  }
  .manage-practice-groups-wrap .h4 {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  /* .feature-hero-wrap h1 {
    font-size: 30px;
  } */
  .feature-hero-layout {
    flex-wrap: wrap;
  }
  .feature-hero {
    max-height: unset;
    min-height: unset;
  }
  .feature-hero-wrap,
  .feature-hero-wrap h1 {
    max-width: 100%;
    width: 100%;
  }
  .feature-hero-img-wrap {
    left: 0;
    width: 100%;
    margin: 20px 0;
  }
  .feature-hero-wrap {
    order: 2;
    margin-bottom: 20px;
  }
  .feature-hero-img-wrap img {
    /* margin-bottom: 10px; */
    width: 100%;
    height: 460px;
    order: 1;
  }
  .manage-your-practice-wrap {
    flex-wrap: wrap;
  }

  .manage-practice-img-wrap img {
    width: 180px;
    height: 180px;
  }
  .manage-practice-img-wrap::after {
    display: none;
  }
  .manage-practice-left-wrap,
  .manage-practice-right-wrap {
    max-width: 300px;
  }
  .manage-your-practice-wrap {
    max-width: 90%;
    margin-top: 20px;
  }
  .manage-practice-groups-wrap .h4 {
    font-size: 16px;
  }
  .payment-solutions-content-wrap {
    margin-left: 0;
  }
  .payment-solutions .btn-wrap {
    margin-bottom: 6rem;
    margin-left: 0;
    text-align: center;
  }
  .payment-solutions-wrap .key-advantages-items {
    height: auto;
    max-width: 400px;
    margin: 0 auto;
  }
  .payment-solutions-wrap .key-advantages-items .h5 {
    font-size: 16px;
  }
  .payment-solutions-img {
    margin-bottom: 20px;
  }
  .payment-solutions-wrap h2 {
    margin-bottom: 5.7rem;
    margin-left: 0;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .feature-hero-img-wrap img {
    height: 420px;
    margin-bottom: 20px;
  }
  .feature-hero-list-wrap {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
  .manage-practice-left-wrap,
  .manage-practice-right-wrap {
    max-width: 100%;
  }
  .manage-practice-right-wrap .manage-practice-groups-wrap {
    flex-direction: row-reverse;
  }
  .manage-practice-left-wrap .link-group-icons {
    left: 0;
  }
  .manage-practice-groups-title-wrap {
    left: 0;
  }
  .manage-practice-right-wrap .link-group-icons {
    right: 0;
  }
  .manage-practice-right-wrap {
    margin-top: 18px;
  }
  .manage-practice-right-wrap .manage-practice-groups-wrap {
    justify-content: flex-end;
    background: linear-gradient(
      270deg,
      #fff 1.8%,
      rgba(255, 255, 255, 0) 75.76%,
      rgba(255, 255, 255, 0) 97.75%
    );
    box-shadow: 70px 0px 80px 0px rgba(0, 0, 0, 0.08);
  }
  .manage-practice-right-wrap .manage-practice-groups-title-wrap {
    padding-left: 0;
    margin-right: 0px;
  }
  .manage-practice-left-wrap .link-group-icons,
  .manage-practice-right-wrap .link-group-icons {
    margin-right: 12px;
  }
  .manage-your-practice-wrap {
    max-width: 100%;
  }
  .payment-solutions-wrap {
    border-top-right-radius: 12rem;
    border-bottom-left-radius: 12rem;
    padding: 9.4rem 25px 10rem 25px;
  }
  .payment-solutions::after {
    max-height: 150px;
  }
  .key-advantages-items:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .feature-hero-img-wrap img {
    height: 380px;
  }
}

@media screen and (max-width: 480px) {
  .feature-hero-img-wrap img {
    height: 300px;
  }
  .button:not(:last-child) {
    margin-right: 14px;
  }
  
}

@media screen and (max-width: 414px) {
  .payment-solutions-wrap .btn-wrap .button{
    width: 100%;
  }
  .payment-solutions-wrap .btn-wrap  .button:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 10px;
  }

}
@media screen and (max-width: 350px) {
  .feature-hero-list-wrap .list-item-wrap .h5 {
    font-size: 15px;
  }
  .feature-hero-img-wrap img {
    height: 250px;
  }
  .feature-hero-wrap h1 {
    font-size: 27px;
  }
  .manage-your-practice h2 {
    margin-bottom: 15px;
  }
  .key-advantages-items:not(:last-child) {
    margin-bottom: 15px;
  }

  .payment-solutions-wrap .key-advantages-items .h5 {
    font-size: 14px;
  }
  .payment-solutions-content-wrap .key-advantages-items {
    padding: 12px 14px;
  }
  .payment-solutions .btn-wrap a {
    width: 100%;
  }
  .button:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  .feature-hero-img-wrap img {
    margin-bottom: 10px;
  }
}
