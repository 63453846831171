.our-story-hero p {
  line-height: 1.7;
  font-weight: 600;
}
.our-story-hero .feature-hero-wrap {
  max-width: 46%;
}
.our-story-hero .feature-hero-img-wrap {
  right: -15rem;
  left: unset;
}

.our-story-hero .feature-hero-img-wrap img {
  width: 64.8rem;
  height: 49.8rem;
}
.why-kinesin-about {
  padding: 6rem 0;
}
.why-kinesin-about p:not(:last-child) {
  margin-bottom: 20px;
}
.why-kinesin-about p:first-child {
  line-height: 1.4;
}
.why-kinesin-about p {
  line-height: 1.8;
  margin-bottom: 0;
}
.aim-kinsin-wrap p {
  position: relative;
  color: #039a65;
  line-height: 1.5;
  max-width: 74%;
}

.aim-kinsin-wrap p::before {
  content: "";
  position: absolute;
  background: url("../../assets/images/icons/quotes-img.png") no-repeat;
  background-size: cover;
  top: -10px;
  left: -10px;
  width: 10.5rem;
  height: 7.9rem;
  z-index: -1;
}

.aim-kinsin {
  margin: 6rem 0 18rem;
}
.arrow-curve-img-wrap {
  margin: 0 90px 0 110px;
  width: 54.4rem;
  height: 20rem;
}
.aim-kinesin-img-wrap {
  width: 35rem;
  height: 30rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.who-we-are {
  padding-bottom: 12rem;
}
.who-we-are-content-wrap {
  padding-left: 10rem;
}
.who-we-are-content-wrap p {
  line-height: 1.8;
  padding-right: 3.5rem;
}

.who-we-are-img-wrap {
  position: relative;
  width: 70.5rem;
  height: 100%;
  padding-bottom: 38.9%;
  flex-shrink: 0;
}
.who-we-are-content-wrap {
  width: calc(100% - 70.5rem);
}
.who-we-are-img-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-top-right-radius: 20rem;
}
.customer-reviews-cards-wrap h2 {
  margin-bottom: 3.8rem;
  margin-top: 4rem;
}
.customer-reviews-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5.6rem;
}
.customer-reviews-cards-wrap .interactions-wrap {
  max-width: unset;
}
.customer-reviews-cards-wrap .interactions-wrap span {
  margin: 0;
  color: #2bfcb8;
}
.dot-circle {
  display: inline-block;
  margin: 0 10px !important;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #2bfcb8;
}
.customer-reviews-card-img {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 64.4%;
  margin-bottom: 3rem;
}
.customer-reviews-card-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-bottom-right-radius: 8rem;
  outline: 2px solid var(--white);
}
.customer-reviews-card-body h4 {
  line-height: normal;
}
.customer-reviews-card-body p {
  padding-right: 5rem;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.customer-reviews-cards-wrap.interactions {
  /* padding: 14.8rem 0; */
  background: #f2f2f2 url("../../assets/images/cover/customer-review-bg.png")
    no-repeat;
  background-size: cover;
  /* margin-top: -6rem; */
}

/* Resposive media query */

@media screen and (max-width: 1799px) {
  .arrow-curve-img-wrap {
    margin: 0 70px 0 190px;
  }
  .aim-kinsin-wrap p {
    max-width: 84%;
  }
}

@media screen and (max-width: 1365px) {
  .why-kinesin-about p:not(:last-child) {
    margin-bottom: 15px;
  }
  .who-we-are-img-wrap {
    width: 470px;
  }
  .who-we-are-content-wrap {
    width: calc(100% - 470px);
  }

  .customer-reviews-cards-wrap h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 1199px) {
  .why-kinesin-about .h2.small,
  .aim-kinsin .h2.small {
    font-size: 20px;
  }
  .our-story-hero .feature-hero-img-wrap img {
    width: 370px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .our-story-hero .feature-hero-img-wrap {
    right: unset;
    left: unset;
  }
  .our-story-hero .feature-hero-wrap {
    max-width: 100%;
  }
  /* .feature-hero-wrap {
    margin-top: 30px;
  } */
  .aim-kinesin-img-wrap {
    display: none;
  }
  .arrow-curve-img-wrap {
    display: none;
  }
  .aim-kinsin-logo-wrap .aim-kinesin-img-wrap {
    position: relative;
    top: unset;
    display: block;
    left: unset;
    transform: unset;
    margin-right: 80px;
  }
  .aim-kinsin-wrap {
    max-width: 80%;
    margin: 0 auto;
  }
  .aim-kinsin-wrap p {
    max-width: 90%;
  }
  .who-we-are-img-wrap,
  .who-we-are-content-wrap {
    width: 100%;
  }
  .who-we-are-content-wrap {
    padding-left: 0;
    margin-top: 20px;
  }
  .who-we-are-img-wrap {
    padding-bottom: 70%;
  }

  .customer-reviews-card-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }
  .customer-reviews-cards-wrap.interactions {
    background: #5e36e9;
    border-bottom-left-radius: 100px;
  }
  .interactions-card-wrapper {
    position: relative;
  }
  .interactions-card-wrapper::after {
    content: "";
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #f2f2f2;
    padding: 0;
  }
  .customer-reviews-card-body p {
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .aim-kinsin-wrap {
    max-width: 100%;
  }
  .aim-kinsin-wrap p::before {
    top: -5px;
    left: -10px;
  }
}
@media screen and (max-width: 575px) {
  .customer-reviews-card-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px;
  }
  .customer-reviews-cards-wrap.interactions {
    padding: 40px 0 60px;
  }
  .customer-reviews-cards-wrap.interactions {
    border-bottom-left-radius: 70px;
  }
  .interactions-card-wrapper::after {
    bottom: -70px;
  }
}
@media screen and (max-width: 480px) {
  .why-kinesin-about .h2.small,
  .aim-kinsin .h2.small {
    font-size: 18px;
  }
  .mission-steps-circle {
    width: 65px;
    height: 65px;
  }
  .aim-kinsin-logo-wrap .aim-kinesin-img-wrap {
    margin-right: 0;
  }
  .aim-kinsin-wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .aim-kinsin-wrap p {
    max-width: 100%;
    margin-top: 20px;
  }
  .aim-kinsin {
    margin-bottom: 40px;
  }
  .why-kinesin-about p {
    line-height: 1.6;
  }
  .who-we-are-content-wrap p {
    line-height: 1.6;
    padding-right: 0;
  }
  .our-story-hero p {
    line-height: 1.5;
    font-size: 16px;
  }
  .customer-reviews-cards-wrap h2 {
    font-size: 24px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 414px) {
  .our-story-hero .feature-hero-img-wrap img {
    width: 300px;
    height: 240px;
  }
}
@media screen and (max-width: 350px) {
  .our-story-hero .feature-hero-img-wrap img {
    width: 260px;
    height: 200px;
  }
}
